<template>
  <div class="app-container billManagementDetails">
    <div class="zhangs">
      <div class="zhangsd title">账单信息</div>
      <div>
        <el-row :gutter="20">
          <el-col :span="6"
            ><div>
              <span class="textd">账单生成时间：</span>{{ item.createTime }}
            </div></el-col
          >
          <el-col :span="6"
            ><div>
              <span class="textd">交易流水号：</span>{{ item.billCode }}
            </div></el-col
          >
          <el-col :span="6"
            ><div>
              <span class="textd">交易时间：</span
              >{{ formatDate(item.tradingTime) }}
            </div></el-col
          >
          <el-col :span="6"
            ><div>
              <span class="textd">发货商家：</span>{{ item.supplier }}
            </div></el-col
          >
          <el-col :span="6"
            ><div>
              <span class="textd">账单金额：</span>{{ item.billAmount }}
            </div></el-col
          >
          <el-col :span="6"
            ><div>
              <span class="textd">结算状态：</span
              >{{ item.status == 0 ? "未结算" : "已结算" }}
            </div></el-col
          >
          <el-col :span="6"
            ><div>
              <span class="textd">预计结算日期：</span
              >{{ item.estimatedSettlementTime }}
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <div class="xuxian mar20"></div>
    <div class="sign" style="margin-bottom: 20px; color: #666666">
      <div class="rightBtn">
        <div style="display: flex">
          <div
            v-for="(item, index) in statusList"
            :key="index"
            class="defaultStyle"
            :class="queryParaming.typeId === item.id ? 'opp' : ''"
            @click="StatusValue(item)"
          >
            {{ item.Name }}
            <span v-if="item.id == 0"
              >· ￥{{ itemed.supplierTotal | number_format }}</span
            >
            <span v-if="item.id == 1"
              >· ￥{{ itemed.distributorTotal | number_format }}</span
            >
            <span v-if="item.id == 2"
              >· ￥{{ itemed.salesmanTotal | number_format }}</span
            >
            <span v-if="item.id == 3"
              >· ￥{{ itemed.platformTotal | number_format }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="queryParaming.typeId == 0">
      <el-tabs type="card" v-model="statusName2" @tab-click="StatusValue2">
        <el-tab-pane
          :label="item.Name"
          v-for="(item, index) in statusList2"
          :key="index"
          :name="item.id"
        >
        </el-tab-pane>
      </el-tabs>
    </div>
    <div v-if="queryParaming.typeId == 3">
      <el-tabs type="card" v-model="statusName3" @tab-click="StatusValue3">
        <el-tab-pane
          :label="item.Name"
          v-for="(item, index) in statusList3"
          :key="index"
          :name="item.id"
        >
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 表格数据 -->
    <div class="dataForm">
      <el-table
        v-loading="loading"
        :data="OrderqueryList"
        border
        style="width: 100%"
        max-height="450"
        ref="tableder"
        :header-cell-style="{ background: '#F2F3F5' }"
      >
        <el-table-column
          align="center"
          fixed
          label="序号"
          type="index"
          width="70"
          key="1"
        ></el-table-column>
        <el-table-column
          prop="ledgerCreateTime"
          label="台账生成时间"
          align="center"
          width="180"
          key="2"
        />
        <el-table-column
          prop="type"
          label="佣金类型"
          align="center"
          key="3"
          v-if="queryParams.type == 3 || queryParams.type == 6"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.type | type }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="类型"
          align="center"
          width="150"
          key="4"
          v-if="queryParams.type == 4"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.type | type }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="收支类型"
          align="center"
          key="5"
          v-if="queryParams.type != 0 && queryParams.type != 1"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.inOutType == 0 ? "收入" : "支出" }}</div>
          </template>
        </el-table-column>

        <el-table-column
          prop="piece"
          label="退款类型"
          align="center"
          key="6"
          v-if="queryParams.type == 1"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.refundType == 0 ? "售后" : "冲货" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="结算对象"
          align="center"
          width="180"
          key="7"
        />
        <el-table-column label="分账流水号/时间" width="250" key="8">
          <template slot-scope="scope">
            <div>{{ scope.row.billCode }}</div>
            <div class="futle">{{ scope.row.billCodeCreateTime }}</div>
          </template>
        </el-table-column>
        <el-table-column label="订单号/时间" width="180" key="9">
          <template slot-scope="scope">
            <div>{{ scope.row.orderId }}</div>
            <div class="futle">{{ scope.row.orderCreateTime }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="groupId"
          label="主订单号"
          align="center"
          width="180"
          key="10"
        />
        <el-table-column
          prop="freight"
          label="运费 (元)"
          align="center"
          width="180"
          key="11"
          v-if="queryParams.type == 2"
        />
        <el-table-column
          prop="refundOrderNumber"
          label="退款单号"
          align="center"
          width="180"
          key="12"
          v-if="queryParams.type == 1 || queryParams.type == 2"
        />
        <el-table-column
          prop="goodsId"
          label="商品ID"
          align="center"
          key="14"
          v-if="queryParams.type != 2"
        />
        <el-table-column
          prop="goodsName"
          label="商品名称"
          align="center"
          key="15"
          width="150"
          show-overflow-tooltip
          v-if="queryParams.type != 2"
        />
        <el-table-column
          prop="goodsSpecification"
          label="规格"
          align="center"
          key="16"
          width="100"
          show-overflow-tooltip
          v-if="queryParams.type != 2"
        />
        <el-table-column
          prop="manufacturer"
          label="生产厂家"
          align="center"
          key="17"
          width="150"
          show-overflow-tooltip
          v-if="queryParams.type != 2"
        />
        <el-table-column
          prop="sellingPrice"
          label="单价 (元)"
          align="center"
          key="18"
          v-if="queryParams.type != 2"
        />
        <el-table-column
          prop="refundPrice"
          label="退款单价 (元)"
          align="center"
          width="150"
          key="19"
          v-if="queryParams.type == 0"
        />
        <el-table-column
          prop=""
          label="退款数量"
          align="center"
          key="20"
          v-if="queryParams.type == 0"
        />
        <el-table-column
          prop="quantity"
          label="订单数量"
          align="center"
          key="21"
          v-if="queryParams.type == 0"
        />
        <el-table-column
          prop="quantity"
          label="订单数量 / 退款数量"
          align="center"
          width="220"
          key="22"
          v-if="
            queryParams.type != 0 &&
            queryParams.type != 1 &&
            queryParams.type != 2
          "
        />
        <el-table-column
          prop="platFormHandlingRatio"
          label="平台手续费 (元/件)"
          align="center"
          width="150"
          key="23"
          v-if="queryParams.type == 1 || queryParams.type == 5"
        />
        <el-table-column
          prop="platformFeeTotal"
          label="手续费小计 (元)"
          align="center"
          width="150"
          key="24"
          v-if="queryParams.type == 5"
        />
        <el-table-column
          prop="platformSharingCommission"
          label="商品佣金 (元/件)"
          align="center"
          width="150"
          key="25"
          v-if="queryParams.type == 1"
        />
        <el-table-column
          prop="total"
          label="结算合计 (元)"
          align="center"
          width="150"
          key="26"
          v-if="queryParams.type == 1"
        />
        <el-table-column
          prop="refundAmount"
          label="退款合计 (元)"
          align="center"
          width="150"
          key="27"
          v-if="queryParams.type == 0 || queryParams.type == 3"
        />
        <el-table-column
          label="佣金 (元/件)"
          align="center"
          width="200"
          key="28"
          v-if="
            queryParams.type == 3 ||
            queryParams.type == 4 ||
            queryParams.type == 6
          "
        >
          <template slot-scope="scope">
            <div v-if="scope.row.type == 3">
              {{ scope.row.saleCommission }}
            </div>
            <div v-if="scope.row.type == 4">
              {{ scope.row.salesmanCommission }}
            </div>
            <div v-if="scope.row.type == 6">
              {{ scope.row.platformSharingCommission }}
            </div>
            <div
              v-if="
                scope.row.type == 9 ||
                scope.row.type == 8 ||
                scope.row.type == 7
              "
            >
              {{ scope.row.whiteBarCommission }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="佣金小计 (元)"
          align="center"
          width="200"
          key="29"
          v-if="
            queryParams.type == 3 ||
            queryParams.type == 4 ||
            queryParams.type == 6
          "
        >
          <template slot-scope="scope">
            <div v-if="scope.row.type == 3">
              {{ scope.row.saleCommissionAmount }}
            </div>
            <div v-if="scope.row.type == 4">
              {{ scope.row.salesmanCommissionAmount }}
            </div>
            <div v-if="scope.row.type == 6">
              {{ scope.row.platformSharingCommissionAmount }}
            </div>
            <div
              v-if="
                scope.row.type == 9 ||
                scope.row.type == 8 ||
                scope.row.type == 7
              "
            >
              {{ scope.row.settlementTotal }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="结算状态" fixed="right" align="center" key="30">
          <template slot-scope="scope">
            <div>{{ scope.row.status == 1 ? "已结算" : "未结算" }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="merch-page">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="queryParams.size"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const settlement = createNamespacedHelpers("settlement");
export default {
  name: "commodinquiry",
  data() {
    return {
      itemed: {
        supplierTotal: "",
        distributorTotal: "",
        salesmanTotal: "",
        platformTotal: "",
      },
      item: JSON.parse(localStorage.getItem("mangeitem")),
      queryParams: {
        page: 1,
        size: 10,
        billId: this.$route.query.id,
        type: 0,
      },
      queryParaming: {
        typeId: "0", //商品状态
        typeId2: "0",
        typeId3: "0",
      },
      loading: false,
      OrderqueryList: [],
      total: 1,
      //商品状态
      statusList: [
        { Name: "共享供应商", id: "0" },
        { Name: "分销供应商", id: "1" },
        { Name: "业务员", id: "2" },
        { Name: "平台", id: "3" },
      ],
      statusList2: [
        { Name: "货款", id: "0" },
        { Name: "退款", id: "1" },
        { Name: "运费", id: "2" },
      ],
      statusName2: "0",
      statusList3: [
        { Name: "手续费", id: "0" },
        { Name: "佣金", id: "1" },
      ],
      statusName3: "0",
    };
  },
  filters: {
    paymentMethod(v) {
      switch (v) {
        case 0:
          return "转账";
        case 1:
          return "在线支付（微信）";
        case 2:
          return "余额";
        case 3:
          return "货到付款";
        case 4:
          return "账期";
        case 5:
          return "提付";
        case 6:
          return "业务员线下付款";
      }
    },
    type(v) {
      switch (v) {
        case 0:
          return "货款";
        case 1:
          return "退款";
        case 2:
          return "运费";
        case 3:
          return "销售佣金";
        case 4:
          return "销售佣金";
        case 5:
          return "平台手续费";
        case 6:
          return "销售佣金";
        case 7:
          return "白条佣金";
        case 8:
          return "白条佣金";
        case 9:
          return "白条佣金";
      }
    },
  },
  created() {
    this.getItemed();
  },
  methods: {
    ...settlement.mapActions(["getSettlementBillListDetail", "getCount"]),
    async getItemed() {
      await this.getList();
      let data = await this.getCount({ billId: this.$route.query.id });
      this.itemed = data.data;
    },
    /** 查询列表数据 */
    async getList() {
      if (this.queryParaming.typeId == 0) {
        if (this.queryParaming.typeId2 == 0) {
          this.queryParams.type = 0;
        }
        if (this.queryParaming.typeId2 == 1) {
          this.queryParams.type = 1;
        }
        if (this.queryParaming.typeId2 == 2) {
          this.queryParams.type = 2;
        }
      }
      if (this.queryParaming.typeId == 1) {
        this.queryParams.type = 3;
      }
      if (this.queryParaming.typeId == 2) {
        this.queryParams.type = 4;
      }
      if (this.queryParaming.typeId == 3) {
        if (this.queryParaming.typeId3 == 0) {
          this.queryParams.type = 5;
        }
        if (this.queryParaming.typeId3 == 1) {
          this.queryParams.type = 6;
        }
      }
      this.loading = true;
      let res = await this.getSettlementBillListDetail(this.queryParams);
      this.OrderqueryList = res.data.list;
      this.total = res.data.totalRecords;
      this.loading = false;
    },
    // 分页
    handleCurrentChange(item) {
      this.queryParams.page = item;
      this.getList();
    },
    /** 点击标记 */
    StatusValue(item) {
      this.queryParaming.typeId = item.id;
      this.queryParams.page = 1;
      this.getList();
    },
    StatusValue2() {
      this.queryParaming.typeId2 = this.statusName2;
      this.queryParams.page = 1;
      this.getList();
    },
    StatusValue3() {
      this.queryParaming.typeId3 = this.statusName3;
      this.queryParams.page = 1;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.app-container {
  width: 100%;
  max-height: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
  height: 100%;
  padding: 20px 15px;
}
.defaultStyle {
  padding: 10px 20px;
  text-align: center;
  line-height: 100%;
  font-size: 14px;
  cursor: pointer;
}
.opp {
  color: #06b7ae;
  border-radius: 5px;
  background-color: #f2f2f2;
}
.oppp {
  color: #06b7ae;
  border-radius: 5px;
  border-bottom: 1px solid #06b7ae;
  border-radius: 0;
}
.el-form-item {
  margin-bottom: 5px;
}
.zhangs {
  line-height: 35px;
  font-size: 12px;
  margin: 0px 10px;
  .zhangsd {
    font-size: 15px;
    font-weight: bold;
  }
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
  }
  .textd{
    color: #666666;
  }
}
</style>
